import React, { useEffect, useState } from "react";
import Header from "./Reapeting Componets/Header";
import Footer from "./Reapeting Componets/Footer";
import uploadIcon from "../Assets/uploadIcon.svg";
import "./Css/career.css";
import DOMPurify from "dompurify";
import {
  server_post_data,
  get_jobs,
  job_app_save,
  job,
} from "../ServiceConnection/serviceconnection";
import {
  formatDateString,
  check_vaild_save,
  handleSuccess,
  combiled_form_data,
  empty_form,
  handleError,
} from "../CommonJquery/CommonJquery.js";
import Loader from "./Reapeting Componets/Loader";
let click_time = 0;
export default function Career() {
  const [showJob, setShowJob] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [jobPosition, setJobPosition] = useState("");
  const [fileName, setFileName] = useState();

  const [annualCTC, setAnnualCTC] = useState("");
  const [fileError, setFileError] = useState("");
  const [selectedJob, setSelectedJob] = useState("");
  const HandleSHowJobDescribtion = (id) => {
    master_data_get_details(id);
    setShowJob(!showJob);
  };

  const [experience, setExperience] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePhoneChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value) && value.length <= 10) {
      setPhone(value);
    }
  };
  const [showLoaderAdmin, setShowLoaderAdmin] = useState(false);
  const [jobListings, setcancelledData] = useState([]);
  const [jobDetails, setjobDetails] = useState([]);

  useEffect(() => {
    master_data_get();
  }, []);
  function truncateWords(text, wordLimit) {
    const words = text.split(" ");
    return words.length > wordLimit
      ? words.slice(0, wordLimit).join(" ") + " ..."
      : text;
  }
  const master_data_get = async () => {
    setShowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("flag", 1);
    await server_post_data(get_jobs, fd)
      .then((Response) => {
        let data = Response.split("~@~");
        if (parseInt(data[0]) === 1) {
          handleError(data[1]);
        } else {
          let final_data = JSON.parse(data[1]);
          console.log(final_data);
          setcancelledData(final_data.vacancy);
          // setSEOloop(final_data.seo_list);
        }
        setShowLoaderAdmin(false);
      })
      .catch((error) => {
        console.log(error);
        setShowLoaderAdmin(false);
      });
  };
  const master_data_get_details = async (id) => {
    setShowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("id", id);
    await server_post_data(job, fd)
      .then((Response) => {
        console.log(Response);
        let data = Response.split("~@~");
        if (parseInt(data[0]) === 1) {
          handleError(data[1]);
        } else {
          let final_data = JSON.parse(data[1]);
          console.log("dddddddd", final_data);
          setjobDetails(final_data.geti);
          // setSEOloop(final_data.seo_list);
        }
        setShowLoaderAdmin(false);
      })
      .catch((error) => {
        console.log(error);
        setShowLoaderAdmin(false);
      });
  };
  const handleNumbersChangeExp = (event) => {
    const value = event.target.value;
    if (value > 25) {
      setShowWarning(true);
    } else {
      setShowWarning(false);
    }
    if (value === "" || (parseInt(value) >= 0 && parseInt(value) <= 25)) {
      setExperience(value);
    }
  };
  const handleApplyClick = (job) => {
    setSelectedJob(job.designation);
    setJobPosition(job.v_id);
    handleActive("formDataShow");
  };
  const handleActive = (id) => {
    const element = document.getElementById(id);
    if (element) {
      const topPosition =
        element.getBoundingClientRect().top + window.pageYOffset;
      const offset = window.innerHeight * 0.2;
      console.log("Scrolling to position:", topPosition - offset);
      window.scrollTo({
        top: topPosition - offset,
        behavior: "smooth",
      });
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFileName(file.name);
    if (file && file.size > 5 * 1024 * 1024) {
      setFileError("File size exceeds 5MB limit.");
      setFileName("");
    } else {
      setFileError("");
      //   handleFileChangedynamic("profile_picture")(e);
    }
  };
  const handleSubmit = async (form_data) => {
    let vaild_data = check_vaild_save(form_data);
    console.log(vaild_data);
    if (vaild_data && click_time === 0) {
      setShowLoaderAdmin(true);
      click_time = 1;
      let fd_from = combiled_form_data(form_data, null);
      await server_post_data(job_app_save, fd_from)
        .then((Response) => {
          setShowLoaderAdmin(false);
          let data1 = Response.split("~@~");
          if (parseInt(data1[0]) === 1) {
            handleError(data1[1]);
          } else {
            empty_form(form_data);
            click_time = 0;
            handleSuccess(data1[1]);
            setFileName("");
          }
        })
        .catch((error) => {
          click_time = 0;
          console.log(error);
          setShowLoaderAdmin(false);
        });
    }
  };
  return (
    <div>
      {showLoaderAdmin && <Loader />}
      <Header />

      <div className="CarrerDiv">
        <div className="carrerHeading">
          <h1>Career</h1>
        </div>
        <div className="container-lg">
          {jobListings &&
            jobListings.length > 0 &&
            jobListings.map((job, index) => (
              <div className="carrerData mt-3" key={index}>
                <div className="GraphicDesdiv">
                  <h2>{job.designation}</h2>
                </div>

                <div className="d-flex justify-content-between align-items-center flex-wrap">
                  <div className="leftySideCareerDe">
                    <i
                      className="fa-solid fa-briefcase pe-2"
                      style={{ color: "white" }}
                    ></i>
                    <p style={{ color: "white" }}>
                      {job.min_experience}-{job.max_experience} Year
                    </p>
                  </div>
                  <div className="RightDivCerrer">
                    <div
                      onClick={() => HandleSHowJobDescribtion(job.v_id)}
                      className="d-flex align-items-center"
                      style={{ cursor: "pointer", gap: "5px" }}
                    >
                      <p style={{ color: "white" }}>View Details</p>
                      <i
                        className="fa-solid fa-caret-down ms-1"
                        style={{ color: "white" }}
                      ></i>
                    </div>

                    <div
                      className="applYjobbttn"
                      onClick={() => handleApplyClick(job)}
                    >
                      <div className="dubArrow">
                        <img
                          src="https://github.com/atloomer/atloomer.github.io/blob/master/img/iconmonstr-arrow-48-240.png?raw=true"
                          alt="Apply Now Icon"
                          title="Apply for Job"
                        />
                      </div>
                      <button type="button">APPLY NOW</button>
                    </div>
                  </div>
                </div>

                {/*---Job Description----------- */}
                {showJob && (
                  <div className="jobDesc">
                    <h2>Job Summary:</h2>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(jobDetails.job_description),
                      }}
                    ></p>
                    <h2>Job Requirements:</h2>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(jobDetails.skills),
                      }}
                    ></p>

                    <h2 className="JobTypeDTa">
                      Job Type:
                      <span style={{ fontWeight: "400", marginLeft: "0.3rem" }}>
                        {jobDetails.designation}
                      </span>
                    </h2>
                    <h2 className="JobTypeDTa">
                      Package we offer:
                      <span style={{ fontWeight: "400", marginLeft: "0.3rem" }}>
                        {jobDetails.salary_details}
                      </span>
                    </h2>
                    {/* <p>{job.jobDescription.closing}</p> */}
                  </div>
                )}
              </div>
            ))}

          <div id="formDataShow" className="formDataShow">
            <form className="row" id="jobFormSave">
              <div className="col-md-6 col-12">
                <input
                  type="text"
                  placeholder="Full Name"
                  id="name"
                  name="name"
                  className="form-control trio_mandatory"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                />
                <span className="condition_error"></span>
              </div>
              <div className="col-md-6 col-12">
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Email"
                  className="form-control trio_mandatory"
                  maxLength={50}
                  minLength={3}
                  value={email}
                  onChange={handleEmailChange}
                  onInput={(e) => {
                    if (!/^\s+|[a-zA-Z0-9@.]*$/.test(e.target.value)) {
                      e.target.value = "";
                    }
                  }}
                />
                <span className="condition_error"></span>
              </div>
              <div className="col-md-6 col-12">
                <input
                  type="text"
                  placeholder="Phone No."
                  id="mobile"
                  name="mobile"
                  className="form-control trio_mandatory"
                  maxLength={10}
                  minLength={10}
                  value={phone}
                  onChange={handlePhoneChange}
                />
                <span className="condition_error"></span>
              </div>
              <div className="col-md-6 col-12">
                <select
                  className="form-control trio_mandatory"
                  value={jobPosition}
                  onChange={(e) => setJobPosition(e.target.value)}
                  id="v_id"
                  name="v_id"
                >
                  <option value="" disabled hidden>
                    {selectedJob || "Job Position"}
                  </option>
                  {jobListings.map((job, index) => (
                    <option key={index} value={job.v_id}>
                      {job.designation}
                    </option>
                  ))}
                </select>
                <span className="condition_error"></span>
              </div>

              <div className="col-md-6 col-12 marginClass">
                <div className="custom-file-upload">
                  <button className="file-upload-btn">
                    <span>Attach your resume</span>
                    <img
                      className="fileUpoloadIcnImg "
                      src={uploadIcon}
                      alt="please Upload Your Resume"
                      title="please Upload Your Resume"
                    />
                  </button>
                  <input
                    type="file"
                    className="file-input "
                    id="file-input"
                    name="profile_picture"
                    accept=".pdf, .doc, .ppt, .pptx"
                    onChange={handleFileChange}
                  />
                  <span className="condition_error"></span>
                </div>
                {fileError && (
                  <span className="fileUpload_error text-danger">
                    {fileError}
                  </span>
                )}
                {fileName}
              </div>

              <div className="col-md-6 col-12 position-relative">
                <input
                  type="text"
                  placeholder="Total Experience in Years"
                  className="form-control trio_mandatory"
                  maxLength={2}
                  name="experience"
                  id="experience"
                  minLength={0}
                  value={experience}
                  onChange={handleNumbersChangeExp}
                />

                {showWarning && (
                  <span className="experCOndition">
                    Please enter a number between 0 and 25
                  </span>
                )}
                <span className="condition_error"></span>
              </div>
              <div className="col-md-6 col-12">
                <input
                  type="text"
                  placeholder="Expected Annual CTC"
                  maxLength={8}
                  id="expected_ctc"
                  name="expected_ctc"
                  className="form-control trio_mandatory"
                  value={annualCTC}
                  onChange={(e) => setAnnualCTC(e.target.value)}
                />
                <span className="condition_error"></span>
              </div>
              <div className="col-12">
                <div className="recaptcha-container">
                  <div
                    className="g-recaptcha"
                    data-sitekey="your-site-key"
                  ></div>
                </div>
              </div>
            </form>

            <div className="subMitButton">
              <div className="applYjobbttn">
                <div
                  className="dubArrow "
                  onClick={() => handleSubmit("jobFormSave")}
                >
                  <img
                    src="https://github.com/atloomer/atloomer.github.io/blob/master/img/iconmonstr-arrow-48-240.png?raw=true"
                    alt="submitform"
                    title="submitform"
                  />
                </div>
                <button type="button">Submit Message</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
